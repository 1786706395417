import React from 'react';
import { withStyles } from '@mui/styles';
import { Grid, Paper } from '@mui/material';
import CustomerSignUpForm from './CustomerSignUpForm';

import BG from './assets/images/CloudHubBg.svg';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 16,
    color: theme.palette.text.secondary,
  },
  rootGrid: {
    display: 'flex',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  signupPaper: {
    flex: 1,
    flexDirection: 'row',
  },
  introBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -150,
    background: `url(${BG}), linear-gradient(to right, #3b3d61, #4d4a78)`,
    transform: 'skew(0, -3.5deg)',
    minHeight: 600,
    zIndex: -10,
  },
  topContainer: {
    flex: 1,
    marginTop: 20,
    opacity: 0.95,
    padding: '0 10px 0 10px',
    position: 'relative',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const Signup = ({ classes }) => (
  <div style={{ flex: 1, height: '100%' }}>
    <div className={classes.introBg}>&nbsp;</div>

    <div className={classes.topContainer}>
      <Grid container style={{ justifyContent: 'center' }}>
        <Grid item sm={12} md={6} />
        <Grid item sm={12} md={6}>
          <Paper className={classes.paper}>
            <h3>Sign up</h3>
            <CustomerSignUpForm />
          </Paper>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default withStyles(styles)(Signup);
