import React from 'react';
import { Redirect, Router } from '@cloudhub-ux/core/reach';
import WebsiteApp from 'app/WebsiteApp';
import Block from '@cloudhub-ux/core/Block';
import ScreenLoading from 'localcomponents/ScreenLoading';
import AuthContext from 'app/auth/context/AuthContext';
import Loadable from '@react-loadable/revised';
import Loading from '@cloudhub-ux/core/Loading';
import useAppContext from 'app/context/useAppContext';

const AdminApp = Loadable({
  loader: () => {
    if (global.window) {
      return import('./admin/AdminApp').then((module) => module.default);
    }
    return null;
  },
  loading: Loading,
});

const HomeRoute = () => {
  const { dispatch } = useAppContext((state) => ({
    dispatch: state.dispatch,
  }));
  const auth = React.useContext(AuthContext);

  React.useEffect(() => {
    if (auth && auth.currentUser) {
      dispatch((state) => ({
        authContext: {
          ...state.authContext,
          ...auth,
        },
      }));
    }
  }, [JSON.stringify(auth)]);

  const { currentUser, loading } = auth;

  const isAdmin = currentUser.UserType.includes('Admin');

  if (loading) {
    return (
      <Block>
        <ScreenLoading />
      </Block>
    );
  }
  return (
    <Router style={{ flex: 1, display: 'flex' }}>
      {!isAdmin && <Redirect noThrow from="/admin" to="/auth/signin" />}
      {isAdmin && <AdminApp path="/app/admin/*" />}
      <WebsiteApp path="/*" default />
    </Router>
  );
};
export default HomeRoute;
