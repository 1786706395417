import sample from 'lodash/sample';

const images = [
  // imageUrls
];

export default {
  siteName: 'MOEST PORTAL',
  homeUrl: 'https://scholarships.moest.go.ke',
  title: 'Welcome',
  description:
    'MOEST Scholarships Portal Allows Students to Applyy for Scholarships',
  image: sample(images),
  tags: [
    'MOEST',
    'Ministry of Education',
    'Scholarship',
    'Journal',
    'Sponsorship',
    'Bursary',
  ],
};
