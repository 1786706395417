// leave off @2x/@3x
import Logo from '../assets/images/Logo.png';
import noImage from '../assets/images/no_available_image.png';
import books from '../assets/icons/Books.svg';
import logoicon from '../assets/images/LogoIcon.png';
import scholarship from '../assets/images/scholarship.jpeg';
const images = {
  Logo,
  noImage,
  logo: Logo,
  logoicon,
  books,
  scholarship,
};

export default images;
