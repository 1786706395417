import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import JournalDetail from './JournalDetail';
import Journals from './Journals';

const JournalRoutes = () => {
  return (
    <Router
      style={{
        display: 'flex',
        flex: 1,
      }}
    >
      <Journals path="/" />
      <JournalDetail path="/:id" />
    </Router>
  );
};

export default JournalRoutes;
