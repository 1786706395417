import React from 'react';
import { GraphqlProvider } from '@cloudhub-ux/graphql';
import CONFIG from 'config/CONFIG';
import useAppContext from './useAppContext';
import AuthProvider from 'app/auth/context/AuthProvider';

const AppProvider = ({ children }: any) => {
  const { authContext } = useAppContext((state) => ({
    authContext: state.authContext,
  }));

  const currentUser = authContext.currentUser;
  let headers = {};
  if (authContext.token) {
    headers = {
      authorization: `Bearer ${authContext.token}`,
      userid: currentUser.id,
      username: currentUser.Name,
      email: currentUser.Email,
      phone: currentUser.Phone,
    };
  }

  return (
    <GraphqlProvider
      url={`${CONFIG.API_ENDPOINT}/gql/www`}
      models={['Page', 'Post', 'User', 'Appsetting', 'Merchant']}
      headers={headers}
    >
      <AuthProvider>{children}</AuthProvider>
    </GraphqlProvider>
  );
};

export default AppProvider;
