import React from 'react';
import {
  Block,
  Text,
  Input,
  PhoneInput,
  Button,
  FieldBlock,
  Alert,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import AuthContext from '../context/AuthContext';
import { colors, sizes } from 'theme';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import { useGraphql } from '@cloudhub-ux/graphql';

const validate = (values) => {
  const errors = {};

  if (values.Password !== values.ConfirmPassword) {
    errors.ConfirmPassword = 'Password does not match';
  }

  return errors;
};

const RegisterScreen = (props) => {
  const { navigate } = useLocation();
  const { autherror, currentUser, actions } = React.useContext(AuthContext);
  const { User, error, setError } = useGraphql();

  const onSubmit = async (values) => {
    const data = await User()
      .completeJhelaRegistration({
        ...values,
      })
      .toJson();

    if (data && data.user && data.token) {
      actions.updateCurrentUser({
        ...data.user,
      });
      actions.authUser(data);
      navigate('/app/home');
    }
  };

  return (
    <Block>
      <Block flex={false}>
        <Text header>Register</Text>
      </Block>
      <Block>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            Phone: currentUser.Phone,
            Email: currentUser.Email,
            Name: currentUser.Name,
          }}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <React.Fragment>
              <Block>
                <FieldBlock row>
                  <Field
                    label="Phone"
                    name="Phone"
                    component={PhoneInput}
                    required
                    disabled
                  />
                  <Field
                    label="Email"
                    name="Email"
                    component={Input}
                    email
                    required
                    flex
                  />
                </FieldBlock>
                <Field
                  label="Your full name (First Middle Last)"
                  name="Name"
                  component={Input}
                  required
                />
                <FieldBlock row>
                  <Field
                    label="OTP (Sent via SMS)"
                    name="OTP"
                    component={Input}
                    required
                    flex
                  />
                </FieldBlock>

                <FieldBlock row>
                  <Field
                    label="Password"
                    name="Password"
                    component={Input}
                    type="password"
                    required
                    minLength={6}
                    flex
                  />
                  <Field
                    label="Confirm Password"
                    name="ConfirmPassword"
                    component={Input}
                    type="password"
                    required
                    flex
                  />
                </FieldBlock>
              </Block>
              <Block flex={false} padding={[sizes.padding, 0]}>
                {error && (
                  <Alert error>
                    <Text error>{error}</Text>
                  </Alert>
                )}
                <Block flex={false} row space="between">
                  <Button
                    onClick={() => {
                      navigate('/app/home/auth/signinsteps/start');
                    }}
                  >
                    <Text>Back</Text>
                  </Button>

                  <Button onClick={handleSubmit} contained>
                    <Text>Next</Text>
                  </Button>
                </Block>
              </Block>
            </React.Fragment>
          )}
        />
      </Block>
    </Block>
  );
};

export default RegisterScreen;
