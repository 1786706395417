import React from 'react';
import { PleaseWait } from '@cloudhub-ux/core';
import SignIn from './Signin';
import AuthContext from './context/AuthContext';

const Signout = () => {
  const { isAuthenticated } = React.useContext(AuthContext);

  if (isAuthenticated) {
    return <PleaseWait />;
  }

  return <SignIn />;
};

export default Signout;
