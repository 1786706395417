import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import { navigate } from '@cloudhub-ux/core/reach';
import Container from '@cloudhub-ux/core/Container';
import Pagination from '@cloudhub-ux/core/Pagination';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import Button from '@cloudhub-ux/core/Button';
import Avatar from '@cloudhub-ux/core/Avatar';
import Text from '@cloudhub-ux/core/Text';
import useGraphql from '@cloudhub-ux/graphql';
import { sizes } from 'theme';
import Download from '@mui/icons-material/Download';

const JournalDetail = ({ id }) => {
  const [journal, setjournal] = React.useState({});

  const { Journal } = useGraphql();

  const getJournal = React.useCallback(async () => {
    try {
      if (Journal) {
        const data = await Journal().findOne({ id }, ['_document']).toJson();
        if (data && data.id) {
          setjournal(data);
        }
      }
    } catch (error) {}
  }, [Journal]);

  React.useEffect(() => {
    getJournal();
  }, [getJournal]);

  return (
    <Block>
      <Text h2 primary>
        {(journal || {}).Subject}
      </Text>
      <Container column maxWidth="lg" padding={sizes.padding}>
        <ListMenu>
          {journals.map((item) => (
            <ListMenuItem
              key={(item || {}).id}
              avatar={
                <Avatar src="" alt={(item || {}).Subject}>
                  {((item || {}).Subject || '').substring(0, 1)}
                </Avatar>
              }
              primary={
                <Block space="between">
                  <Text h3 bold>
                    {(item || {}).Subject || ''}
                  </Text>
                  <Block right row flex={false}>
                    <Button
                      onClick={() => navigate(`/app/jornal/${item.id}`)}
                      endIcon={<Download />}
                    >
                      Download
                    </Button>
                  </Block>
                </Block>
              }
            />
          ))}
        </ListMenu>
      </Container>
    </Block>
  );
};

export default JournalDetail;
