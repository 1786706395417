import React from 'react';

import { Text, Block } from '@cloudhub-ux/core';
import { colors } from 'theme';
import useAppContext from 'app/context/useAppContext';
import useAppSettings from '../useAppSettings';

const ContactDetails = ({ color = colors.milkyWhite }) => {
  const { settingsContext } = useAppContext((state) => ({
    settingsContext: state.settingsContext,
  }));
  const { appActions } = useAppSettings();
  const { Organization } = settingsContext || {};
  const Address = Organization.Address || {};

  React.useEffect(() => {
    appActions.getOrganization();
  }, []);

  return (
    <Block
      flex
      column
      padding={10}
      style={{ color: 'inherit', minWidth: 300, textColor: color }}
    >
      <Text h2 bold color={color}>
        {Organization.OrganizationName}
      </Text>
      <Text header bold color={color}>
        {Address.Line1}
      </Text>
      <Text header bold color={color}>
        {Address.Line2}
      </Text>
      <Text header bold color={color}>{`Email: ${Organization.Email}`}</Text>
      <Text header bold color={color}>{`Phone: ${Organization.Phone}`}</Text>
    </Block>
  );
};

export default ContactDetails;
