import React from 'react';
import { makeStyles } from '@mui/styles';
import { Block, Container, Text } from '@cloudhub-ux/core';
import PageSeo from '@cloudhub-ux/core/seo/PageSeo';

import { colors, Images } from 'theme';
import useAppContext from 'app/context/useAppContext';

const useStyles = makeStyles({
  body: {
    marginBottom: 40,
    '& img': {
      width: '100%',
      margin: 10,
    },
  },
});

const About = () => {
  const classes = useStyles();

  const { settingsContext } = useAppContext((state) => ({
    settingsContext: state.settingsContext,
  }));
  const { StaticPages } = settingsContext || {};
  const about = StaticPages.find(
    (page) => page && page.PageCode === 'home_about'
  );

  if (!about) {
    return null;
  }

  return (
    <Block>
      <PageSeo
        title="Who we are"
        image={Images.twitterImage}
        description="The purpose of starting the business was to help businesses bridge the gap that is created between financial service providers like Banks, Mpesa, etc and automation of business processes like"
      />
      <Block flex={false} color={colors.secondary} style={{ height: 120 }}>
        <Container maxWidth="lg" row middle flex>
          <Text h2>{about.Title}</Text>
        </Container>
      </Block>
      <Container maxWidth="lg">
        <Text
          className={classes.Body}
          dangerouslySetInnerHTML={{ __html: about.Body }}
        />
      </Container>
    </Block>
  );
};

export default About;
