import { SW_INIT, SW_UPDATE, SW_REGISTER } from './swActions';

const swReducer = (state, action) => {
  switch (action.type) {
    case SW_REGISTER: {
      return {
        ...state,
        registeredServiceWorker: action.payload,
      };
    }
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: Date.now(),
        registeredServiceWorker: action.payload,
      };
    default:
      return state;
  }
};

export default swReducer;
