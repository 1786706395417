import create from 'zustand/vanilla';
import swReducer from './swReducer';

const INITIAL_STATE = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: null,
  registeredServiceWorker: null,
};

const swStore = create((set) => ({
  ...INITIAL_STATE,
  dispatch: (action) => set((state) => swReducer(state, action)),
}));

export default swStore;
