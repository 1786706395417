import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Container from '@cloudhub-ux/core/Container';
import Pagination from '@cloudhub-ux/core/Pagination';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import Button from '@cloudhub-ux/core/Button';
import Avatar from '@cloudhub-ux/core/Avatar';
import Text from '@cloudhub-ux/core/Text';
import { useGraphql } from '@cloudhub-ux/graphql';
import { navigate } from '@cloudhub-ux/core/reach';
import { sizes, colors } from 'theme';
import useScholarship from './store/useScholarship';
import dayjs from 'dayjs';

const Scholarships = () => {
  const [scholarships, setscholarships] = React.useState([]);
  const [scholarshipCount, setscholarshipCount] = React.useState(0);
  const [page, setpage] = React.useState(0);

  const { scholarshipActions } = useScholarship();

  const { editApplication } = scholarshipActions;

  const { Scholarship } = useGraphql();

  const getScholarships = React.useCallback(async () => {
    try {
      if (Scholarship) {
        const data = await Scholarship()
          .find({ skip: page * 20, limit: 20 })
          .toJson();
        if (data && Array.isArray(data.items)) {
          setscholarships(data.items);
          setscholarshipCount(data.totalCount);
        }
      }
    } catch (error) {}
  }, [Scholarship, page]);

  React.useEffect(() => {
    getScholarships();
  }, [getScholarships]);

  return (
    <Block>
      <Block color={colors.primary} padding={sizes.section} flex={false}>
        <Text h1 bold milkyWhite>
          All Scholarships
        </Text>
      </Block>

      <Container column maxWidth="lg" padding={sizes.padding}>
        <ListMenu style={{ minHeight: '70vh' }}>
          {scholarships.map((item) => (
            <ListMenuItem
              key={(item || {}).id}
              avatar={
                <Avatar
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.milkyWhite,
                    fontSize: 32,
                  }}
                  size={65}
                  src=""
                  alt={(item || {}).ScholarshipName}
                >
                  {((item || {}).ScholarshipName || '').substring(0, 1)}
                </Avatar>
              }
              primary={
                <Block row space="between">
                  <Text h3 bold>
                    {(item || {}).ScholarshipName || ''}
                  </Text>
                  <Button
                    contained
                    onClick={() => {
                      editApplication({
                        Scholarship: {
                          id: (item || {}).id,
                          _id: (item || {})._id,
                          ScholarshipName: (item || {}).ScholarshipName,
                          Startdate: (item || {}).Startdate,
                          Deadline: (item || {}).Deadline,
                        },
                      });
                      navigate('/app/scholarship/apply');
                    }}
                  >
                    <Text h3 bold>
                      Apply
                    </Text>
                  </Button>
                </Block>
              }
              secondary={`${dayjs((item || {}).StartDate).format(
                'YYYY-MM-DDTHH:mm:ssZ[Z]'
              )} - ${dayjs((item || {}).Deadline).format(
                'YYYY-MM-DDTHH:mm:ssZ[Z]'
              )}`}
            />
          ))}
        </ListMenu>
        <Block row center>
          <Pagination
            count={scholarshipCount}
            onChangePage={(p) => setpage(p)}
            rowsPerPage={20}
          />
        </Block>
      </Container>
    </Block>
  );
};

export default Scholarships;
