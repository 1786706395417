import React from 'react';
import { Link } from '@cloudhub-ux/core/reach';
import { Text, Block } from '@cloudhub-ux/core';
import useAppSettings from '../useAppSettings';

const AboutDetails = () => {
  const { appActions } = useAppSettings();

  React.useEffect(() => {
    try {
      appActions.getStaticPages();
    } catch (error) {}
  }, []);

  return (
    <Block
      flex
      padding={10}
      style={{
        color: 'inherit',
        minWidth: 300,
        '& a': {
          color: 'inherit',
        },
        '& a:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      <Text h2 white>
        About
      </Text>
      <Link to="/about">
        <Text bold link white>
          The Company
        </Text>
      </Link>
      <Link to="/about/terms">
        <Text bold link white>
          Terms & Conditions
        </Text>
      </Link>
      <Link to="/about/contactus">
        <Text bold link white>
          Contact us
        </Text>
      </Link>
    </Block>
  );
};

export default AboutDetails;
