import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Block,
  FieldBlock,
  Text,
  Input,
  IconButton,
  Button,
  PhoneInput,
} from '@cloudhub-ux/core';
import {
  DialogActions,
  DialogHeader,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';
import { ArrowBack } from '@mui/icons-material';
import { colors } from 'theme';

const validate = (values) => {
  const errors = {};

  if (!values.Phone) {
    errors.Phone = 'Required';
  }
  if (!values.Email) {
    errors.Email = 'Required';
  }
  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.Password) {
    errors.Password = 'Required';
  }
  if (values.Password) {
    if (values.Password.length < 4) {
      errors.Password = 'Too Short';
    }
  }

  if (values.Password !== values.ConfirmPassword) {
    errors.ConfirmPassword = 'Passwords do not match';
  }

  return errors;
};

const SignupScreen = (props) => (
  <Form
    onSubmit={props.onSubmit}
    validate={validate}
    initialValues={props.initialValues || {}}
    render={({ handleSubmit, submitting }) => (
      <Block>
        <DialogHeader onClose={props.onClose}>
          <Text header normal>
            Sign Up
          </Text>
        </DialogHeader>
        <DialogContent column middle>
          <Block flex={false}>
            <FieldBlock>
              <label>Full Name</label>
              <Field type="text" name="Name" component={Input} />
            </FieldBlock>
          </Block>
          <FieldBlock row>
            <FieldBlock flex>
              <label>Phone</label>
              <Field type="text" name="Phone" readOnly component={PhoneInput} />
            </FieldBlock>
            <FieldBlock flex>
              <label>Email</label>
              <Field type="text" name="Email" component={Input} />
            </FieldBlock>
          </FieldBlock>
          <FieldBlock>
            <label>Password</label>
            <Field type="password" name="Password" component={Input} />
          </FieldBlock>
          <FieldBlock>
            <label>Cconfirm Password</label>
            <Field type="password" name="ConfirmPassword" component={Input} />
          </FieldBlock>
        </DialogContent>
        <DialogActions space="between">
          <IconButton disabled={submitting} onClick={props.onBack}>
            <ArrowBack style={{ color: colors.dark, fontSize: 42 }} />
          </IconButton>
          <Button color="dark" disabled={submitting} onClick={handleSubmit}>
            <Text button>Sign up</Text>
          </Button>
        </DialogActions>
      </Block>
    )}
  />
);

SignupScreen.defaultProps = {
  onBack: () => {},
  onClose: () => {},
  onSubmit: () => {},
};

export default SignupScreen;
