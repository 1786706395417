import React from 'react';
import { Link } from '@cloudhub-ux/core/reach';
import { withStyles } from '@mui/styles';

import {
  Block,
  Text,
  Input,
  Alert,
  Button,
  FieldBlock,
  Container,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { sizes } from 'theme';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import AuthContext from './context/AuthContext';

const styles = () => ({});

const Signin = (props) => {
  const { navigate } = useLocation();
  const {
    merchantCode,
    isAuthenticated,
    errorMessage,
    actions: { signinUser, closeSignIn }, // loginWithFacebook, loginWithGoogle
  } = React.useContext(AuthContext);

  // const onFacebookLogin = params => {
  //   loginWithFacebook({
  //     params,
  //   });
  // };

  // const onGoogleLogin = params => {
  //   loginWithGoogle({
  //     params,
  //   });
  // };

  const handleFormSubmit = async ({ Email, Password }) => {
    await signinUser({
      Email,
      Password,
    });
  };

  const renderAlert = () => {
    if (errorMessage) {
      return (
        <Alert
          message="There were errors with your login"
          description={errorMessage}
          type="error"
        />
      );
    }
    return null;
  };

  return (
    <Block flex={false} row wrap>
      <Block>
        <Container maxWidth="sm">
          <Form
            onSubmit={handleFormSubmit}
            render={({ handleSubmit }) => (
              <Block column>
                <Block>
                  <FieldBlock>
                    <label>Email</label>
                    <Field name="Email" component={Input} email />
                  </FieldBlock>
                  <FieldBlock>
                    <label>Password</label>
                    <Field type="password" name="Password" component={Input} />
                  </FieldBlock>
                </Block>

                {renderAlert()}
                <Block flex={false}>
                  <Block margin={[10, 0]} row middle space="between">
                    <Button
                      color="primary"
                      contained
                      medium
                      onClick={handleSubmit}
                    >
                      <Text white body>
                        Sign in
                      </Text>
                    </Button>
                    <Link to="/auth/resetpassword">
                      <Button fullWidth onClick={() => closeSignIn()}>
                        <Text color="primary">Forgot password?</Text>
                      </Button>
                    </Link>
                  </Block>
                </Block>
              </Block>
            )}
          />
        </Container>
      </Block>

      <Block center middle>
        <Container maxWidth="sm">
          <Block>
            <Block center>
              <Text h3 center style={{ marginBottom: sizes.margin }}>
                Welcome
              </Text>
            </Block>
          </Block>
        </Container>
      </Block>
    </Block>
  );
};

export default withStyles(styles)(Signin);
