import React from 'react';
import { Block, Text, Input, Button, Alert, toastr } from '@cloudhub-ux/core';
import { Link } from '@cloudhub-ux/core/reach';
import { Form, Field } from '@cloudhub-ux/core/form';
import AuthContext from '../context/AuthContext';
import { colors, sizes } from 'theme';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import { useGraphql } from '@cloudhub-ux/graphql';

const PasswordScreen = (props) => {
  const { navigate } = useLocation();
  const { autherror, currentUser, actions } = React.useContext(AuthContext);
  const { User, error, setError } = useGraphql();
  const [resetting, setResetting] = React.useState(false);
  const [forgotpassword, setForgotPassword] = React.useState(false);

  const onSubmit = async (values) => {
    const data = await User()
      .signIn({
        ...values,
      })
      .toJson();

    if (data && data.token) {
      actions.updateCurrentUser({
        ...data.user,
      });
      actions.authUser(data);
      navigate('/app/home');
    }
  };

  const resetPassword = async (values) => {
    setResetting(true);
    const data = await User()
      .resetPassword({
        ...values,
      })
      .toJson();

    setResetting(false);

    if (data) {
      toastr.success(`Password reset successfully and sent to ${values.Phone}`);
      setForgotPassword(false);
    }
  };

  return (
    <Block>
      <Block flex={false}>
        <Text header>Sign In</Text>
      </Block>
      <Block>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            Phone: currentUser.Phone,
            Email: currentUser.Email,
            Name: currentUser.Name,
          }}
          render={({ handleSubmit, submitting, values }) => (
            <React.Fragment>
              <Block>
                {currentUser.Email ? (
                  <Field
                    label="Email"
                    name="Email"
                    component={Input}
                    required
                    disabled
                  />
                ) : (
                  <Field
                    label="Phone"
                    name="Phone"
                    component={Input}
                    required
                    disabled
                  />
                )}

                <Field
                  label="Password"
                  name="Password"
                  component={Input}
                  type="password"
                  required
                  minLength={6}
                  autoFocus
                />
                <Block flex={false}>
                  <Link
                    onClick={() => setForgotPassword(true)}
                    to="#"
                    style={{ marginTop: 10 }}
                  >
                    Forgot Password?
                  </Link>
                  {forgotpassword && (
                    <Block
                      flex={false}
                      borderRadius
                      padding
                      color={colors.green[100]}
                    >
                      <Text bold success>
                        It is ok to forget your password. But dont let it be
                        known to others!
                      </Text>
                      <Button onClick={() => resetPassword(values)} contained>
                        {resetting ? 'Please wait...' : 'Get a new password'}
                      </Button>
                    </Block>
                  )}
                </Block>
              </Block>
              <Block flex={false} padding={[sizes.padding, 0]}>
                {error && (
                  <Alert error>
                    <Text error>{error}</Text>
                  </Alert>
                )}
                <Block flex={false} row space="between">
                  <Button
                    onClick={() => {
                      navigate('/app/home/auth/signinsteps/start');
                    }}
                  >
                    <Text>Back</Text>
                  </Button>

                  <Button onClick={handleSubmit} contained>
                    <Text>Sign In</Text>
                  </Button>
                </Block>
              </Block>
            </React.Fragment>
          )}
        />
      </Block>
    </Block>
  );
};

export default PasswordScreen;
