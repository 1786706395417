import React from 'react';
import useAppContext from 'app/context/useAppContext';
import useGraphql from '@cloudhub-ux/graphql';
import CONFIG from 'config/CONFIG';

export const InitialSettingsState = {
  StaticPages: [],
  Organization: {
    id: '',
    OrganizationName: CONFIG.OrganizationName,
    Logo: {},
  },

  SystemSettings: {},
};

const useScholarship = () => {
  const { settingsContext, dispatch } = useAppContext((state) => ({
    settingsContext: state.settingsContext,
    dispatch: state.dispatch,
  }));

  const { Page, Appsetting } = useGraphql();

  const settingsActions = {
    editApplication: (application) => {
      dispatch((state) => ({
        settingsContext: {
          ...state.settingsContext,
          editingApplication: application,
        },
      }));
    },
  };

  const getPages = React.useCallback(async () => {
    try {
      if (Page) {
        const data = await Page().find({}).toJson();
        if (data && Array.isArray(data.items)) {
          dispatch((state) => ({
            settingsContext: {
              ...state.settingsContext,
              StaticPages: data.items,
            },
          }));
        }
      }
    } catch (error) {}
  }, [Page]);
  const getSystemSettings = React.useCallback(async () => {
    try {
      if (Appsetting) {
        const org = await Appsetting()
          .findOne({ id: 'Organization' }, ['_document'])
          .toJson();
        const systemsettings = await Appsetting()
          .findOne({ id: 'SyystemSettiings' }, ['_document'])
          .toJson();
        if (org || systemsettings) {
          dispatch((state) => ({
            settingsContext: {
              ...state.settingsContext,
              ...(org && org.id ? { Organization: org } : {}),
              ...(systemsettings && systemsettings.id
                ? { SystemSettings: systemsettings }
                : {}),
            },
          }));
        }
      }
    } catch (error) {}
  }, [Appsetting]);

  React.useEffect(() => {
    getSystemSettings();
    getPages();
    // eslint disable next line
  }, []);

  return {
    ...settingsContext,
    settingsActions,
  };
};

export default useScholarship;
