import React, { Component } from 'react';
import ThemeProvider from 'theme/ThemeProvider';
import SocketProvider from 'app/socket/SocketProvider';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { hot } from 'react-hot-loader/root';
import UpdatesListener from 'serviceworkers/UpdatesListener';
import HomeRoute from './HomeRoute';

function getEmotionCache() {
  return createCache({ key: 'css', prepend: true });
}

const cache = getEmotionCache();

class App extends Component {
  componentDidMount() {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    return (
      <CacheProvider value={cache}>
        <ThemeProvider>
          <SocketProvider>
            <HomeRoute />
            <UpdatesListener />
          </SocketProvider>
        </ThemeProvider>
      </CacheProvider>
    );
  }
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
