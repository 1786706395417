import React from 'react';

import {
  Input,
  PhoneInput,
  Alert,
  Block,
  FieldBlock,
  Button,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';

import AuthContext from './context/AuthContext';

const validate = (values) => {
  const errors = {};

  if (values.Password !== values.ConfirmPassword) {
    errors.ConfirmPassword = 'Passwords do not match';
  }

  return errors;
};

const Signup = () => {
  const {
    errorMessage,
    actions: { signupUser },
  } = React.useContext(AuthContext);

  const handleFormSubmit = (values) => {
    const vals = { ...values };
    delete vals.confirmpassword;
    signupUser({ user: values });
  };

  const renderAlert = () => {
    if (errorMessage) {
      return <Alert message="There were errors with your registration" />;
    }
    return null;
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={validate}
      initialValues={{}}
      render={({ handleSubmit, submitting, pristine }) => (
        <Block>
          <FieldBlock>
            <label>Full Name:</label>
            <Field name="Name" component={Input} required />
          </FieldBlock>
          <FieldBlock row wrap>
            <FieldBlock flex>
              <label>Email</label>
              <Field name="Email" component={Input} email required />
            </FieldBlock>
            <FieldBlock flex>
              <label>Phone</label>
              <Field type="tex" name="Phone" component={PhoneInput} required />
            </FieldBlock>
          </FieldBlock>
          <FieldBlock>
            <label>Password</label>
            <Field type="password" name="Password" component={Input} required />
          </FieldBlock>
          <FieldBlock>
            <label>Confirm Password</label>
            <Field name="ConfirmPassword" component={Input} required />
          </FieldBlock>

          {renderAlert()}
          <FieldBlock row>
            <Button
              medium
              contained
              onClick={handleSubmit}
              disabled={submitting || pristine}
            >
              Sign up
            </Button>
          </FieldBlock>
        </Block>
      )}
    />
  );
};

export default Signup;
