import React from 'react';
import { Block, Text } from '@cloudhub-ux/core';
import { sizes, colors } from 'theme';

const WelcomeScreen = (props) => {
  const [] = React.useState(0);

  return (
    <Block>
      <Text header>Welcome!</Text>

      <Block
        flex={false}
        margin={[sizes.margin, 0]}
        color={colors.green[100]}
        padding
        borderRadius
      >
        <Text color={colors.green[600]}>
          You registration is successful. If there are additional steps to
          complete registration, You will be guided. Keep your password secure.
        </Text>
      </Block>
    </Block>
  );
};

export default WelcomeScreen;
