import React from 'react';
import {
  Block,
  Text,
  FieldBlock,
  FieldButton,
  Button,
  PhoneInput,
  Input,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { sizes, colors } from 'theme';
import AuthContext from 'app/auth/context/AuthContext';
import { useGraphql } from '@cloudhub-ux/graphql';
import { useLocation } from '@cloudhub-ux/core/customhooks';

const StartScreen = (props) => {
  const { currentUser, actions } = React.useContext(AuthContext);
  const { navigate } = useLocation();
  const { User, error, setError } = useGraphql();

  const getOtp = async (values) => {
    const { Phone, ...Country } = values;
    const data = await User().checkJhelaPhone({ Phone, Country }).toJson();

    if (data && !data.token && `${data._id}`.includes('registration/')) {
      actions.updateCurrentUser({
        Phone,
        Country,
        OTPTimestamp: data.OTPTimestamp,
        Name: data.Name || '',
        Email: data.Email || '',
      });

      navigate('/app/home/auth/signinsteps/register');
    }

    if (data && !data.token && `${data._id}`.includes('user/')) {
      actions.updateCurrentUser({
        Phone,
      });
      navigate('/app/home/auth/signinsteps/signin');
    }
  };

  return (
    <Block>
      <Block>
        <Text h2>{`Sign in`}</Text>

        <Block flex={false}>
          <Text dark>Start with your phone or email address.</Text>
        </Block>

        <Form
          onSubmit={getOtp}
          initialValues={{
            Phone: currentUser.Phone,
          }}
          render={({ submitting, handleSubmit, form, values }) => {
            return (
              <Block>
                <Block>
                  <FieldBlock row>
                    <Field
                      label="Phone"
                      name="Phone"
                      component={PhoneInput}
                      onPhoneChanged={(val) => {
                        if (val) {
                          form.change('Country', val.country);
                          form.change('CountryCode', val.cca2);
                          form.change('Currency', val.currency);
                        }
                      }}
                      required
                      flex
                    />
                  </FieldBlock>
                </Block>
                <Block flex={false} style={{ paddingBottom: sizes.padding }}>
                  {error && (
                    <Block
                      flex={false}
                      padding={sizes.padding}
                      margin={[sizes.margin, 0]}
                      style={{ borderRadius: 8 }}
                      color={colors.red[100]}
                    >
                      <Text error>{error}</Text>
                    </Block>
                  )}
                  <Block flex={false}>
                    <Button
                      contained
                      onClick={handleSubmit}
                      disabled={submitting || !values.Phone}
                    >
                      <Text>Next</Text>
                    </Button>
                  </Block>
                </Block>
              </Block>
            );
          }}
        />
      </Block>
    </Block>
  );
};

export default StartScreen;
