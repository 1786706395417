import React from 'react';
import SeoProvider from '@cloudhub-ux/core/seo/SeoProvider';
import SiteConfig from 'theme/SiteConfig';

import Home from './Home';
import RoutesContainer from './RoutesContainer';

const WebsiteApp = () => {
  return (
    <SeoProvider {...SiteConfig}>
      <Home default>
        <RoutesContainer />
      </Home>
    </SeoProvider>
  );
};

export default WebsiteApp;
