import React from 'react';
import { Text, Block } from '@cloudhub-ux/core';

const MobileApps = (props) => (
  <Block
    flex
    column
    style={{
      padding: 10,
      color: 'inherit',
      minWidth: 300,
      '& a': {
        color: 'inherit',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
    }}
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://play.google.com/store/apps/details?id=com.manifestedpublishers.elearning"
    >
      <Text body>
        <img
          alt="download on google play"
          src="assets/images/google-play-logo.png"
          style={{ height: 65, marginBottom: 10 }}
        />
      </Text>
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://itunes.apple.com/us/app/cloudtrading/id1407420831?mt=8&ign-mpt=uo%3D4"
    >
      <Text body>
        <img
          alt="download on the app store"
          src="assets/images/app-store-logo.png"
          style={{ height: 65 }}
        />
      </Text>
    </a>
  </Block>
);

export default MobileApps;
