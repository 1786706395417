import React from 'react';
import { ListMenu, ListSubMenu, ListMenuItem } from '@cloudhub-ux/core';
import { Link } from '@cloudhub-ux/core/reach';

import {
  // Lens as LensIcon,
  Home as HomeIcon,
  Lens,
} from '@mui/icons-material';

const SideMenu = () => (
  <ListMenu>
    <ListSubMenu header="Main Menu" expanded>
      <Link to="/">
        <ListMenuItem
          icon={<HomeIcon style={{ height: 14 }} />}
          primary="Home"
        />
      </Link>

      <Link to="/app/about">
        <ListMenuItem icon={<Lens style={{ height: 14 }} />} primary="about" />
      </Link>
      <Link to="/app/about/terms">
        <ListMenuItem
          icon={<Lens style={{ height: 14 }} />}
          primary="Terms & Conditions"
        />
      </Link>
    </ListSubMenu>
    <ListSubMenu header="Scholarships" expanded>
      <Link to="/app/scholarship">
        <ListMenuItem
          icon={<HomeIcon style={{ height: 14 }} />}
          primary="All Scholarships"
        />
      </Link>

      <Link to="/app/scholarship/myscholarships">
        <ListMenuItem
          icon={<Lens style={{ height: 14 }} />}
          primary="My Scholarships"
        />
      </Link>
      <Link to="/app/scholarship/apply">
        <ListMenuItem
          icon={<Lens style={{ height: 14 }} />}
          primary="Apply for Scholarship"
        />
      </Link>
    </ListSubMenu>
  </ListMenu>
);

export default SideMenu;
