import React from 'react';
import { Text, Block } from '@cloudhub-ux/core';
import AuthContext from 'app/auth/context/AuthContext';
import { useMetrics } from '@cloudhub-ux/core/customhooks';
import ContactForm from './ContactForm';
import ContactDetails from './footer/ContactDetails';
import { colors, sizes } from '../../theme';

const ContactUs = () => {
  const { maxWidth } = useMetrics();
  const { currentUser } = React.useContext(AuthContext);
  const { Name, Email, Phone } = currentUser;
  return (
    <Block center middle padding={sizes.padding} color={colors.gray4}>
      <Text style={{ color: '#FFFFFF' }} h3>
        Thank you for contacting us
      </Text>
      <Text header style={{ color: colors.cyan, marginBottom: 40 }}>
        Please fill in the details below
      </Text>
      <Block
        row={maxWidth === 'lg'}
        middle
        color={colors.white}
        padding={sizes.padding}
        flex={false}
        paper
      >
        <ContactDetails color={colors.black} />
        <ContactForm
          initialValues={{
            Name: Name === 'Guest' ? '' : Name,
            Email,
            Phone,
          }}
        />
      </Block>
    </Block>
  );
};
export default ContactUs;
