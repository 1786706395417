import React from 'react';
import ReactDOM from 'react-dom';
// import { preloadReady } from '@react-loadable/revised';
import swStore from 'serviceworkers/store/swStore';

import './index.css';
import {
  SW_INIT,
  SW_UPDATE,
  SW_REGISTER,
} from 'serviceworkers/store/swActions';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const dispatch = swStore.getState().dispatch;

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register({
  onRegister: (registration) => {
    dispatch({ type: SW_REGISTER, payload: registration });
  },
  onSuccess: (registration) => {
    dispatch({ type: SW_INIT, payload: registration });
  },
  onUpdate: (registration) => {
    dispatch({
      type: SW_UPDATE,
      payload: registration,
    });
  },
});
