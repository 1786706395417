import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import AuthRoutes from 'app/auth/AuthRoutes';
import Dashboard from './Dashboard';
import StaticRoutes from './appsettings/StaticRoutes';
import Signin from './auth/Signin';
import ScholarshipRoutes from './scholarship/ScholarshipRoutes';
import JournalRoutes from './journal/JournalRoutes';

const RoutesContainer = () => (
  <Router
    style={{
      display: 'flex',
      flex: 1,
    }}
  >
    <Dashboard default path="/" />
    <ScholarshipRoutes path="/app/scholarship/*" />
    <JournalRoutes path="/app/journal/*" />
    <AuthRoutes path="/app/home/auth/*" />
    <Signin path="/app/home/auth" />
    <StaticRoutes path="/app/about/*" />
  </Router>
);

export default RoutesContainer;
