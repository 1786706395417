import CONFIG from 'config/CONFIG';
import queryString from 'query-string';

import {
  SET_LOADING,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  SET_TOKEN,
  UPDATE_PROFILE,
  UPDATE_CURRENT_USER,
} from './actions/authTypes';

const maxTime = process.env.NODE_ENV === 'production' ? 3 * 60000 : 30 * 60000;

let fulldomain = '';
let parsed = {};

if (global.window) {
  fulldomain = global.window.location.host;
  parsed = queryString.parse(global.window.location.search) || {};
}

let token = parsed.token || '';

try {
  if (!token) {
    token = localStorage.getItem('token');
  }
} catch (error) {}

export const INITIAL_STATE = {
  token,
  isAuthenticated: false,
  autherror: '',
  currentUser: {
    Name: 'Guest',
    Email: '',
    Phone: '',
    UserType: ['Customer'],
  },
  organization: {
    OrganizationName: CONFIG.OrganizationName,
    Logo: {
      ...CONFIG.Logo,
    },
  },
  loading: false,
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER: {
      if (localStorage) {
        localStorage.setItem('token', action.payload.token);
      }
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        currentUser: action.payload.user,
        sessioncounter: maxTime,
        error: '',
      };
    }
    case UNAUTH_USER: {
      if (localStorage) {
        localStorage.removeItem('token');
      }
      return {
        ...INITIAL_STATE,
        token: '',
      };
    }
    case AUTH_ERROR: {
      if (localStorage && action.payload) {
        localStorage.removeItem('token');
      }

      return { ...state, autherror: action.payload };
    }
    case SET_TOKEN:
      return { ...state, token: action.payload };

    case UPDATE_PROFILE: {
      return { ...state, currentUser: { ...action.payload } };
    }

    case SET_LOADING: {
      return { ...state, loading: action.payload || false };
    }

    case UPDATE_CURRENT_USER: {
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    }

    default:
      return state;
  }
};

export default authReducer;
