import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Drawer, Slide } from '@mui/material';

import { Block } from '@cloudhub-ux/core';
import SideMenu from './SideMenu';

// import { sizes, colors } from 'theme';

const styles = {
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
};

class LeftDrawer extends Component {
  static defaultProps = {
    onClose: () => null,
  };

  state = {};

  render() {
    const { open, onClose } = this.props;

    let isMobile = false;

    if (global.navigator) {
      isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
    }

    return (
      <Block>
        {isMobile ? (
          <Drawer open={open} onClose={onClose}>
            <div
              tabIndex={0}
              role="button"
              onClick={onClose}
              onKeyDown={onClose}
            >
              <SideMenu />
            </div>
          </Drawer>
        ) : (
          <Block>
            <Slide direction="right" in={open} mountOnEnter unmountOnExit>
              <Block tabIndex={0} role="button" style={{ width: 270 }}>
                <SideMenu />
              </Block>
            </Slide>
          </Block>
        )}
      </Block>
    );
  }
}

export default withStyles(styles)(LeftDrawer);
