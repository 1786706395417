import React from 'react';
import useAuthentication from './useAuthentication';
import AuthContext from './AuthContext';
import CONFIG from 'config/CONFIG';

const AuthProvider = ({ children, ...props }) => {
  const {
    loading,
    isAuthenticated,
    currentUser,
    token,
    merchantCode,
    actions,
    ...rest
  } = useAuthentication();

  React.useEffect(() => {
    if (token && !isAuthenticated) {
      actions.checkAuthStatus();
    }
  }, [token, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        currentUser,
        UserRoles: currentUser.UserRoles || [],
        UserType: currentUser.UserType,
        merchantUser: currentUser.DefaultMerchant || {},
        token,
        merchantCode,
        actions,
        CONFIG,
        ...props,
        ...rest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
