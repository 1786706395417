import React from 'react';
import socketIOClient from 'socket.io-client';
import sailsIOClient from '@cloudhub-ux/sails.io.js';
import queryString from 'query-string';
import CONFIG from 'config/CONFIG';

const io = sailsIOClient(socketIOClient);

const getHttpHost = (path) => {
  const p = path || '';
  return window.location ? window.location.origin + p : `ws://127.0.0.1${p}`;
};

const mainSocketUrl =
  process.env.NODE_ENV === 'production'
    ? getHttpHost().replace(CONFIG.clientPort, CONFIG.serverPort)
    : `http://${CONFIG.ipAddress}:${CONFIG.serverPort}`;

io.sails.url = mainSocketUrl;
io.sails.autoConnect = false;
io.sails.useCORSRouteToGetCookie = false;
io.sails.reconnection = true;
io.sails.environment = process.env.NODE_ENV || 'development';

let sailsconnecting = false;

const useSailsIo = ({
  headers = {},
  onConnect = () => null,
  requiredHeaderProperty,
}) => {
  // const sailsRef = React.useRef(sails);

  const [mainSocket, setMainSocket] = React.useState(null);
  const [socket, setRawSocket] = React.useState(null);
  const [socketid, setSocketId] = React.useState(null);
  const [connected, setConnected] = React.useState(false);

  const connectToServer = React.useMemo(
    () => () => {
      const connectAlready = async () => {
        if (sailsconnecting) {
          return true;
        }
        sailsconnecting = true;
        io.sails.headers = headers;
        io.sails.query = queryString.stringify(headers);

        const mainSocket = io.sails.connect(mainSocketUrl);

        mainSocket.on('connect', () => {
          const rawSocket = mainSocket._raw;
          setRawSocket(rawSocket);
          setSocketId(rawSocket.id);
          setMainSocket(mainSocket);
          setConnected(true);

          onConnect({ mainSocket });
        });

        mainSocket.on('disconnect', () => {
          setConnected(false);
        });
      };

      if (requiredHeaderProperty) {
        if (headers[requiredHeaderProperty]) {
          connectAlready();
        }
      } else {
        connectAlready();
      }
    },
    [requiredHeaderProperty, headers, onConnect]
  );

  React.useEffect(() => {
    if (!connected) {
      connectToServer();
    }
  }, [headers, connected, connectToServer]);

  return {
    mainSocket,
    connected,
    socket,
    socketid,
  };
};

export default useSailsIo;
