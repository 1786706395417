import { navigate } from '@cloudhub-ux/core/reach';
let href = global.window ? window.location.href || '' : '';
href = href.toLowerCase();
export function autoNavigate(DefaultMerchant) {
  const UserType = (DefaultMerchant.UserType || []).join(',');

  if (
    UserType.includes('SuperAdmin') ||
    UserType.includes('MerchantAdmin') ||
    UserType.includes('MerchantAssistantAdmin')
  ) {
    if (!href.includes(`/${DefaultMerchant.MerchantType}`)) {
      navigate(`/app/${DefaultMerchant.MerchantType || ''}`);
    }
  } else if (UserType.includes('MerchantCashier')) {
    const route = '/app/pos';
    if (!href.includes(route)) {
      navigate(route);
    }
  }
}

export function afterSignIn(user) {
  if (user.UserType.includes('Admin')) {
    if (!href.includes('/app/admin')) {
      return navigate('/app/admin');
    } else {
      return;
    }
  }

  navigate('/');
}
