import React from 'react';
import { navigate } from '@reach/router';
import { useGraphql } from '@cloudhub-ux/graphql';
import toastr from '@cloudhub-ux/core/toastr';
import useAppContext from 'app/context/useAppContext';

const useAppSettings = () => {
  const { settingsContext, dispatch } = useAppContext((state) => ({
    settingsContext: state.settingsContext,
    dispatch: state.dispatch,
  }));

  const { Appsetting, Support, Page } = useGraphql();

  const getOrganization = React.useCallback(async () => {
    try {
      if (Appsetting && !((settingsContext || {}).Organization || {}).id) {
        const data = await Appsetting()
          .findOne({ id: 'Organization' })
          .toJson();

        if (data && data.id) {
          dispatch((state) => ({
            settingsContext: {
              ...state.settingsContext,
              Organization: data,
            },
            merchantContext: {
              ...state.merchantContext,
              Organization: data,
            },
          }));
        }
      }
    } catch (error) {}
  }, [Appsetting, JSON.stringify(settingsContext)]);

  const getStaticPages = React.useCallback(async () => {
    try {
      if (Page && !Array.isArray((settingsContext || {}).StaticPages)) {
        const data = await Page().find({}).toJson();

        if (data && Array.isArray(data.items)) {
          dispatch((state) => ({
            settingsContext: {
              ...state.settingsContext,
              StaticPages: data.items,
            },
          }));
        }
      }
    } catch (error) {}
  }, [Page, JSON.stringify(settingsContext)]);

  const openDrawer = (opened) => {
    dispatch((state) => ({
      settingsContext: {
        ...state.settingsContext,
        drawerOpen: opened,
      },
    }));
  };

  const contactUs = async ({ ticket }) => {
    try {
      const data = await Support().contactus({
        ...ticket,
      });
      toastr.success('Thank you for your message we will get back to you ASAP');
      navigate(`/app/about/contactus-thankyou/${data.TicketNo}`);
    } catch (error) {}
  };

  const hideNav = (hidden) => {
    dispatch((state) => ({
      settingsContext: { ...state.settingsContext, hidenav: hidden },
    }));
  };

  return {
    ...settingsContext,
    appActions: {
      openDrawer,
      contactUs,
      hideNav,
      getOrganization,
      getStaticPages,
    },
  };
};

export default useAppSettings;
