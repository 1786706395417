import React from 'react';
import { Text, Block, Container } from '@cloudhub-ux/core';
import { colors } from 'theme';
import useAppContext from 'app/context/useAppContext';

const Terms = () => {
  const { settingsContext } = useAppContext((state) => ({
    settingsContext: state.settingsContext,
  }));
  const { StaticPages } = settingsContext || {};
  const tnc = StaticPages.find(
    (page) => page && page.PageCode === 'home_terms'
  );

  if (!tnc) {
    return null;
  }

  return (
    <Block>
      <Block flex={false} color={colors.secondary} style={{ height: 120 }}>
        <Container maxWidth="lg" row middle flex>
          <Text h2>{tnc.Title}</Text>
        </Container>
      </Block>
      <Container maxWidth="lg">
        <Text
          style={{ marginBottom: 40 }}
          dangerouslySetInnerHTML={{ __html: tnc.Body }}
        />
      </Container>
    </Block>
  );
};

export default Terms;
