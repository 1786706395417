import React, { Component } from 'react';
import { navigate } from '@cloudhub-ux/core/reach';
import { Grid, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Block, Text } from '@cloudhub-ux/core';

import BG from './assets/images/CloudHubBg.svg';

// import Logo from '../../assets/images/no_available_image.png';
import './auth.css';
import AuthContext from './context/AuthContext';

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 16,
    color: theme.palette.text.secondary,
    minWidth: 400,
    maxWidth: 500,
    minHeight: 400,
  },
  rootGrid: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logoArea: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  introBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -150,
    background: `url(${BG}), linear-gradient(to right, #3b3d61, #4d4a78)`,
    transform: 'skew(0, -3.5deg)',
    minHeight: 600,
    zIndex: -10,
  },
  topContainer: {
    flex: 1,
    marginTop: 20,
    opacity: 0.95,
    padding: '0 10px 0 10px',
    position: 'relative',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const ResetPasswordSuccess = ({ classes }) => {
  const { merchantCode } = React.useContext(AuthContext);
  React.useEffect(() => {
    setTimeout(() => {
      const isBusiness = ['www', 'business'].includes(merchantCode);
      if (merchantCode && !isBusiness) {
        navigate('/app/home');
      } else {
        navigate('/app/home/auth/signin');
      }
    }, 5000);
  }, []);

  return (
    <div>
      <div className={classes.introBg}>&nbsp;</div>

      <div className={classes.topContainer}>
        <Grid container style={{ justifyContent: 'center' }}>
          <Paper className={classes.paper}>
            <Block middle center>
              <Text h5 green>
                You have successfully reset your password. The new password will
                be sent to your email/sms. Please login and change to your
                preffered password immidiately. This page will be redirected in
                a few seconds.
              </Text>
            </Block>
          </Paper>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(ResetPasswordSuccess);
