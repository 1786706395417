import React from 'react';
import axios from 'axios';
import CONFIG from 'config/CONFIG';
import useSocketEventListener from 'app/socket/useSocketEventListener';
import useServiceWorkerStore from './store/useServiceWorkerStore';
import { SW_UPDATE } from './store/swActions';

let localapp = localStorage.getItem('appsetting');

if (localapp) {
  try {
    localapp = JSON.parse(localapp);
  } catch (error) {
    localapp = null;
  }
}

const axiosInstance = () =>
  axios.create({
    baseURL: CONFIG.API_ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
    },
  });

const UpdatesListener = () => {
  const [app, setApp] = React.useState(localapp || {});

  const dispatch = useServiceWorkerStore((state) => state.dispatch);

  const serviceWorkerUpdated = useServiceWorkerStore(
    (state) => state.serviceWorkerUpdated
  );
  const registeredServiceWorker = useServiceWorkerStore(
    (state) => state.registeredServiceWorker
  );

  const getApp = async () => {
    try {
      const { data } = await axiosInstance().get(
        '/homeapp/settings/appsetting/getapp'
      );

      if (data) {
        setApp(data);
        if (localStorage) {
          localStorage.setItem('appsetting', JSON.stringify(data));
        }
      }
    } catch (error) {}
  };

  const checkAppVersion = React.useMemo(
    () => async (appsettings) => {
      try {
        const settings = appsettings || {};
        if (settings.Version !== app.Version) {
          setApp(appsettings);
          localStorage.setItem('appsetting', JSON.stringify(appsettings));
          // Reload app to trigger update if, app version is different
          const reg = await registeredServiceWorker.update();
          dispatch({
            type: SW_UPDATE,
            payload: reg,
          });
        }
      } catch (error) {
        console.log(error.toString());
      }
    },
    [app.Version, registeredServiceWorker]
  );

  React.useEffect(() => {
    getApp();
  }, []);

  React.useEffect(() => {
    const updateApplication = () => {
      const registrationWaiting = registeredServiceWorker.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        if (window && window.location) {
          setTimeout(() => {
            window.location.reload();
          }, 15000); // Wait for 10 secs to reload the page
        }
      } else {
        if (window && window.location) {
          setTimeout(() => {
            window.location.reload();
          }, 15000); // Wait for 10 secs to reload the page
        }
      }
    };

    if (serviceWorkerUpdated) {
      updateApplication();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceWorkerUpdated]);

  // We can use sockets to force reload on the clients whenever we push a new update
  useSocketEventListener('APP_SETTINGS', checkAppVersion);

  return null;
};

export default UpdatesListener;
