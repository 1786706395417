import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Block,
  FieldBlock,
  Text,
  PhoneInput,
  IconButton,
} from '@cloudhub-ux/core';
import {
  DialogActions,
  DialogHeader,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';
import { ArrowForward } from '@mui/icons-material';
import { colors, sizes } from 'theme';

const validate = (values) => {
  const errors = {};

  if (!values.Phone) {
    errors.Phone = 'Validate';
  }

  return errors;
};

const PhoneScreen = (props) => (
  <Form
    onSubmit={props.onSubmit}
    validate={validate}
    initialValues={props.initialValues || {}}
    render={({ handleSubmit, submitting }) => (
      <Block>
        <DialogHeader onClose={props.onClose}>
          <Text header normal>
            Get Started
          </Text>
        </DialogHeader>
        <DialogContent column middle>
          <Block flex={false}>
            <FieldBlock>
              <Text h4 style={{ marginBottom: sizes.margin }}>
                Please enter your Phone Number
              </Text>
              <Field
                type="text"
                name="Phone"
                component={PhoneInput}
                marginRight={0}
              />
            </FieldBlock>
          </Block>
        </DialogContent>
        <DialogActions>
          <IconButton color="dark" disabled={submitting} onClick={handleSubmit}>
            <ArrowForward style={{ color: colors.milkyWhite, fontSize: 42 }} />
          </IconButton>
        </DialogActions>
      </Block>
    )}
  />
);

PhoneScreen.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
};

export default PhoneScreen;
