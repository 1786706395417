import { toastr } from '@cloudhub-ux/core';
import { navigate } from '@cloudhub-ux/core/reach';
import {
  SET_LOADING,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  SET_TOKEN,
  UPDATE_CURRENT_USER,
} from './authTypes';
import CONFIG from 'config/CONFIG';
const authActions = ({ dispatch, axiosinstance, merchantCode }) => {
  // let href = global.window ? window.location.href || '' : '';
  // href = href.toLowerCase();

  function afterSignIn() {
    // redirect to home or admin
  }

  const actions = {
    authUser: (data) => {
      dispatch({
        type: AUTH_USER,
        payload: data,
      });
    },

    signinUser: async (values) => {
      try {
        actions.setLoading();
        const { data } = await axiosinstance().post(`/auth/user/emaillogin`, {
          ...values,
        });

        actions.authUser(data);
        const user = data.user || {};
        actions.setLoading(false);
        if (typeof CONFIG.afterSignIn === 'function') {
          CONFIG.afterSignIn(user);
        } else {
          afterSignIn(user);
        }

        // return { token: data.token, user: data.user };
      } catch (error) {
        const response = error.response || {};
        const data = response.data || {};

        toastr.error(`Error signing in: ${data.message || error.toString()}`);
        actions.setLoading(false);
        actions.authError(
          `Error signing in: ${data.message || error.toString()}`
        );
        return {};
      }
    },

    checkPhoneNumber: ({ Phone }) =>
      axiosinstance()
        .get(`/auth/user/checkphone`, {
          params: { Phone },
        })
        .then(({ data }) => Promise.resolve(data))
        .catch(() => Promise.reject()),

    signoutUser: () => {
      if (localStorage) {
        localStorage.removeItem('token');
      }
      navigate('/app/home');
      return dispatch({
        type: UNAUTH_USER,
      });
    },

    signupUser: ({ user }) => {
      axiosinstance()
        .post(`/auth/user/signup`, user)
        .then(({ data }) => {
          actions.authUser(data);
          actions.closeSignIn();
        })
        .catch((error) => {
          const { response } = error;
          const message = response
            ? response.data.message || 'Error in your request'
            : error;
          toastr.error(message);
          actions.authError(message);
        });
    },

    checkAuthStatus: async () => {
      let token;
      try {
        dispatch({
          type: AUTH_ERROR,
          payload: '',
        });
        token = localStorage.getItem('token');
      } catch (error) {
        //
      }

      dispatch({
        type: SET_TOKEN,
        payload: token,
      });

      try {
        actions.setLoading();
        const { data } = await axiosinstance().post(`/auth/user/getuser`, {
          token,
        });

        actions.authUser(data);
        const user = data.user || {};
        actions.setLoading(false);
        if (typeof CONFIG.afterSignIn === 'function') {
          CONFIG.afterSignIn(user);
        } else {
          afterSignIn(user);
        }
      } catch (error) {
        const response = error.response || {};
        const data = response.data || {};

        if (`${data.message}`.includes('EXPIRED')) {
          navigate('/app/home');
        }

        dispatch({
          type: AUTH_ERROR,
          payload: `${data.message || 'Server error'}`.replace(/_/g, ' '),
        });
      }
    },

    resetPassword: ({ Email }) =>
      axiosinstance()
        .post(`/auth/user/resetpassword`, { Email })
        .then(() => {
          navigate('/auth/resetpasswordsuccess');
          return Promise.resolve();
        })
        .catch(() => {
          toastr.error('Problem with your code');
        }),

    changePassword: ({ CurrentPassword, Password }) => {
      axiosinstance()
        .post(`/auth/user/changepassword`, {
          CurrentPassword,
          Password,
        })
        .then(() => {
          actions.closeChangePassword();
          toastr.success('Your password was reset successfully');
        })
        .catch(() => {
          toastr.error('Problem with your code');
        });
    },

    updateProfile: (profile) => {
      axiosinstance()
        .post(`/auth/user/updateprofile`, profile)
        .then(({ data }) => {
          actions.authUser(data);
          toastr.success('Your profile is now updated');
        })
        .catch((error) => {
          const response = error.response || {};
          const data = response.data || {};
          toastr.error(data.message || 'Problem with your profile');
        });
    },

    setToken: (token) =>
      dispatch({
        type: SET_TOKEN,
        payload: token,
      }),

    authError: (error) =>
      dispatch({
        type: AUTH_ERROR,
        payload: error,
      }),

    setLoading: (status = true) => {
      dispatch({
        type: SET_LOADING,
        payload: status,
      });
    },
    updateCurrentUser: (params) => {
      dispatch({
        type: UPDATE_CURRENT_USER,
        payload: params,
      });
    },
  };

  return actions;
};
export default authActions;
