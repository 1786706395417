import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Block,
  FieldBlock,
  Text,
  Input,
  Button,
  IconButton,
} from '@cloudhub-ux/core';
import {
  DialogActions,
  DialogHeader,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';
import { ArrowBack } from '@mui/icons-material';
import { colors, sizes } from 'theme';

const validate = (values) => {
  const errors = {};

  if (!values.Password) {
    errors.Password = 'Required';
  }

  return errors;
};

const OTPScreen = (props) => (
  <Form
    onSubmit={props.onSubmit}
    validate={validate}
    initialValues={props.initialValues || {}}
    render={({ handleSubmit, submitting }) => (
      <Block>
        <DialogHeader onClose={props.onClose}>
          <Text header normal>
            Enter your password
          </Text>
        </DialogHeader>
        <DialogContent column middle>
          <FieldBlock>
            <label>Password</label>
            <Field type="password" name="Password" component={Input} />
          </FieldBlock>
          <Block row flex={false} style={{ marginTop: sizes.margin }}>
            <Button onClick={props.onForgotPassword}>
              <Text primary>Have you forgotten your password?</Text>
            </Button>
          </Block>
        </DialogContent>

        <DialogActions space="between">
          <IconButton disabled={submitting} onClick={props.onBack}>
            <ArrowBack style={{ color: colors.dark, fontSize: 42 }} />
          </IconButton>
          <Button color="dark" disabled={submitting} onClick={handleSubmit}>
            <Text button>Sign in</Text>
          </Button>
        </DialogActions>
      </Block>
    )}
  />
);

OTPScreen.defaultProps = {
  onBack: () => {},
  onSubmit: () => {},
  onForgotPassword: () => {},
};

export default OTPScreen;
