import React from 'react';
import { Block, toastr } from '@cloudhub-ux/core';
import { colors } from 'theme';
import ContactDetails from './footer/ContactDetails';
import AboutDetails from './footer/AboutDetails';
import SocialMedia from './footer/SocialMedia';
import MobileApps from './footer/MobileApps';

const Footer = () => {
  const infoClick = () => {
    toastr.info(
      'Cloud Hub Limited',
      'please visit www.cloudhub.co.ke for more information',
      {
        timeOut: 15000,
        position: 'bottom-center',
        progressBar: false,
        transitionOut: 'bounceOut',
      }
    );
  };

  return (
    <Block
      row
      wrap
      space="between"
      padding={[50, 25, 70, 25]}
      flex={false}
      color={colors.dark}
      style={{ zIndex: 2 }}
    >
      <ContactDetails />
      <AboutDetails />
      <MobileApps />
      <SocialMedia />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          color: '#DDDDDD',
          fontSize: 10,
        }}
      >
        <div
          role="button"
          tabIndex="-1"
          style={{ cursor: 'pointer' }}
          onClick={infoClick}
        >
          ©2018 Developed By Cloud Hub Ltd. All Rights Reserved.
        </div>
      </div>
    </Block>
  );
};
export default Footer;
