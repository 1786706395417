import React from 'react';
import CONFIG from 'config/CONFIG';
import LocationProvider from '@cloudhub-ux/core/customhooks/LocationProvider';
import AppContextProvider from '@cloudhub-ux/core/context/AppContextProvider';
import { ThemeProvider as MinThemeProvider } from '@cloudhub-ux/min';
import AppProvider from 'app/context/AppProvider';
import INITIAL_STATE from 'app/context/INITIAL_STATE';
import colors from '../../../webapp/src/theme/Colors';
import sizes from '../../../webapp/src/theme/Sizes';
import fonts from '../../../webapp/src/theme/Fonts';

const ThemeProvider = ({ children, ...props }) => (
  <MinThemeProvider
    colors={colors}
    fonts={fonts}
    sizes={sizes}
    CONFIG={CONFIG}
    {...props}
  >
    <LocationProvider>
      <AppContextProvider
        INITIAL_STATE={{
          ...INITIAL_STATE,
          sharedComponents: {
            ...INITIAL_STATE.sharedComponents,
          },
        }}
      >
        <AppProvider>{children}</AppProvider>
      </AppContextProvider>
    </LocationProvider>
  </MinThemeProvider>
);

export default ThemeProvider;
