import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import MyScholarships from './MySholarships';
import Scholarships from './Scholarships';
import Loadable from '@react-loadable/revised';
import Loading from '@cloudhub-ux/core/Loading';

const ScholarshipApplicationForm = Loadable({
  loader: () => {
    if (global.window) {
      return import('./ScholarshipApplicationForm').then(
        (module) => module.default
      );
    }
    return null;
  },
  loading: Loading,
});

const ScholarshipRoutes = () => {
  return (
    <Router
      style={{
        display: 'flex',
        flex: 1,
      }}
    >
      <Scholarships path="/" />
      <ScholarshipApplicationForm path="apply" />
      <MyScholarships path="myscholarships" />
    </Router>
  );
};

export default ScholarshipRoutes;
