import React from 'react';
import { Slide } from '@mui/material';

import { Block } from '@cloudhub-ux/core';
import Dialog from '@cloudhub-ux/core/dialogs';

import AuthContext from 'app/auth/context/AuthContext';

import PhoneScreen from './PhoneScreen';
import PasswordScreen from './PasswordScreen';
import SignupScreen from './SignupScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';

const GetStarted = () => {
  const {
    getstarted,
    actions: {
      closeGetStarted,
      signupUser,
      signinUser,
      resetPassword,
      checkPhoneNumber,
    },
  } = React.useContext(AuthContext);

  const [screen, setScreen] = React.useState('phonescreen');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');

  const onSubmitPhone = async (values) => {
    await checkPhoneNumber(values)
      .then(({ phone, email }) => {
        setScreen('passwordscreen');
        setPhone(phone);
        setEmail(email);
      })
      .catch(() => {
        setScreen('signupscreen');
        setPhone(values.Phone);
      });
  };

  const onSignUp = async (values, form) => {
    await signupUser({ user: values });
    setTimeout(form.reset);

    closeGetStarted();
  };

  const onSignIn = async (values, form) => {
    await signinUser({
      email: values.Email,
      password: values.Password,
    });
    setTimeout(form.reset);

    closeGetStarted();
  };

  const onResetPassword = async (values, form) => {
    await resetPassword({
      email: values.Email,
    });
    setTimeout(form.reset);
    setScreen('passwordscreen');
  };

  return (
    <Dialog
      maxWidth="sm"
      minHeight={500}
      open={getstarted}
      onClose={closeGetStarted}
    >
      <Block>
        {screen === 'phonescreen' && (
          <Block>
            <Slide direction="right" in={screen === 'phonescreen'}>
              <Block>
                <PhoneScreen
                  initialValues={{ Phone: phone }}
                  onClose={closeGetStarted}
                  onSubmit={onSubmitPhone}
                />
              </Block>
            </Slide>
          </Block>
        )}
        {screen === 'signupscreen' && (
          <Block>
            <Slide direction="left" in={screen === 'signupscreen'}>
              <Block>
                <SignupScreen
                  initialValues={{ phone }}
                  onSubmit={onSignUp}
                  onClose={closeGetStarted}
                  onBack={() => setScreen('phonescreen')}
                />
              </Block>
            </Slide>
          </Block>
        )}
        {screen === 'passwordscreen' && (
          <Block>
            <Slide direction="left" in={screen === 'passwordscreen'}>
              <Block>
                <PasswordScreen
                  initialValues={{ email }}
                  onSubmit={onSignIn}
                  onClose={closeGetStarted}
                  onBack={() => setScreen('phonescreen')}
                  onForgotPassword={() => setScreen('forgotpasswordscreen')}
                />
              </Block>
            </Slide>
          </Block>
        )}

        {screen === 'forgotpasswordscreen' && (
          <Block>
            <Slide direction="left" in={screen === 'forgotpasswordscreen'}>
              <Block>
                <ForgotPasswordScreen
                  initialValues={{ email }}
                  onSubmit={onResetPassword}
                  onClose={closeGetStarted}
                  onBack={() => setScreen('phonescreen')}
                />
              </Block>
            </Slide>
          </Block>
        )}
      </Block>
    </Dialog>
  );
};

GetStarted.defaultProps = {
  open: false,
  onClose: () => {},
  onOk: () => {},
};

export default GetStarted;
