import React from 'react';
import {
  Form,
  Field,
  Button,
  Input,
  TextArea,
  FieldBlock,
  Block,
  PhoneInput,
  toastr,
} from '@cloudhub-ux/core';
import './contactform.css';
import { colors } from 'theme';
import CONFIG from 'config/CONFIG';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import AuthContext from 'app/auth/context/AuthContext';

const ContactForm = (props) => {
  const { navigate } = useLocation();
  const { axiosinstance } = React.useContext(AuthContext);

  const onSendForm = async (ticket) => {
    try {
      const { data } = await axiosinstance().post(
        `${CONFIG.API_ENDPOINT}/website/app/support/contactus`,
        { ...ticket }
      );

      toastr.success('Thank you for your message we will get back to you ASAP');
      navigate(`/contactus-thankyou/${data.TicketNo}`);
    } catch (error) {
      const response = error.response || {};
      const data = response.data || {};
      toastr.error(data.message || error.message || error);
    }
  };

  return (
    <Block flex column>
      <Form
        onSubmit={onSendForm}
        initialValues={props.initialValues}
        render={({ handleSubmit }) => (
          <Block>
            <Block margin={[5, 0, 0, 0]} padding={[0, 10, 0, 0]}>
              <Field
                type="text"
                name="Name"
                label="Your Name"
                component={Input}
                required
              />
            </Block>
            <FieldBlock row>
              <Field
                label="Your email"
                name="Email"
                component={Input}
                type="email"
                flex
                required
                email
              />
              <Field
                label="Your Mobile No."
                name="Phone"
                component={PhoneInput}
                flex
                required
              />
            </FieldBlock>

            <Field
              type="text"
              name="Subject"
              label="Subject of your message"
              component={Input}
              required
            />

            <FieldBlock>
              <label>Your Message</label>
              <Field
                component={TextArea}
                props={{ rows: 9 }}
                style={{
                  width: '100%',
                }}
                name="Message"
                placeholder="Please put your message here..."
                required
              />
            </FieldBlock>

            <Block flex={false} row right>
              <Button contained color={colors.secondary} onClick={handleSubmit}>
                Send
              </Button>
            </Block>
          </Block>
        )}
      />
    </Block>
  );
};

export default ContactForm;
