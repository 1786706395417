import React from 'react';
import {
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
} from '@mui/icons-material';
import { Block, Text } from '@cloudhub-ux/core';
import { colors, sizes } from 'theme';

const AboutDetails = () => (
  <Block
    column
    space="between"
    style={{
      '& a': {
        color: 'inherit',
      },
      '& a:hover': {
        textDecoration: 'none',
      },
    }}
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.facebook.com/ManifestedPublishers"
    >
      <Block row middle>
        {/* <Badge
          padding={sizes.base}
          margin={[0, sizes.base, 0, sizes.base]}
          size={24}
          color={colors.facebookColor}
        > */}
        {/* <Icon type="facebook" /> */}
        <FacebookIcon
          style={{
            color: colors.facebookColor,
            marginRight: sizes.baseMargin,
          }}
        />
        {/* </Badge> */}
        <Text milkyWhite link bold>
          Facebook
        </Text>
      </Block>
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/manifested_publishers/?hl=en"
    >
      <Block row middle>
        {/* <Badge
          padding={sizes.base}
          margin={[0, sizes.base, 0, sizes.base]}
          size={24}
          color={colors.milkyWhite}
        > */}
        {/* <Icon type="instagram" /> */}
        <InstagramIcon
          style={{
            color: colors.instagramColor,
            marginRight: sizes.baseMargin,
          }}
        />
        {/* </Badge> */}
        <Text milkyWhite link bold>
          Instagram
        </Text>
      </Block>
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://twitter.com/ManifestedP"
    >
      <Block row middle>
        {/* <Badge
          padding={sizes.base}
          margin={[0, sizes.base, 0, sizes.base]}
          size={24}
          color={colors.twitterColor}
        > */}
        {/* <Icon type="twitter" /> */}
        <TwitterIcon
          style={{
            color: colors.twitterColor,
            marginRight: sizes.baseMargin,
          }}
        />
        {/* </Badge> */}
        <Text milkyWhite link bold>
          Twitter
        </Text>
      </Block>
    </a>
  </Block>
);

export default AboutDetails;
