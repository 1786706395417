import React from 'react';

import { Input, FieldButton, Block, FieldBlock, Text } from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { sizes } from 'theme';
import AuthContext from './context/AuthContext';

const ResetPasswordForm = () => {
  const {
    actions: { resetPassword },
  } = React.useContext(AuthContext);

  const onResetPassword = (values) => {
    const { Email } = values;
    resetPassword({ Email });
  };

  return (
    <Form
      onSubmit={onResetPassword}
      render={({ handleSubmit }) => (
        <Block>
          <FieldBlock row top>
            <Field
              component={Input}
              placeholder="Enter your email"
              label={` `}
              name="Email"
              email
              required
              flex
            />

            <FieldButton
              contained
              color="success"
              onClick={handleSubmit}
              style={{ height: sizes.inputHeight }}
            >
              <Text white>Reset</Text>
            </FieldButton>
          </FieldBlock>
        </Block>
      )}
    />
  );
};

export default ResetPasswordForm;
