import React from 'react';
import { Block, Container } from '@cloudhub-ux/core';
import { sizes } from 'theme';
import { Router } from '@cloudhub-ux/core/reach';
import StartScreen from './StartScreen';
import RegisterScreen from './RegisterScreen';
import WelcomeScreen from './WelcomeScreen';
import PasswordScreen from './PasswordScreen';

const SignInStepper = ({ navigate }) => (
  <Container maxWidth="sm" style={{ flexDirection: 'column' }}>
    <Block
      paper
      flex={false}
      padding={sizes.padding}
      style={{ minHeight: 500 }}
    >
      <Router
        basepath="/app/home/auth/signinsteps"
        style={{ flex: 1, display: 'flex' }}
      >
        <StartScreen path="start" default />
        <PasswordScreen path="signin" />
        <RegisterScreen path="register" />
        <WelcomeScreen path="welcome" />
      </Router>
    </Block>
  </Container>
);

export default SignInStepper;
