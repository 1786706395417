import React from 'react';
import useAppContext from 'app/context/useAppContext';

export const InitialScholarshipState = {
  editingApplication: null,
};

const useScholarship = () => {
  const { scholarshipContext, dispatch } = useAppContext((state) => ({
    scholarshipContext: state.scholarshipContext,
    dispatch: state.dispatch,
  }));

  const scholarshipActions = {
    editApplication: (application) => {
      dispatch((state) => ({
        scholarshipContext: {
          ...state.scholarshipContext,
          editingApplication: application,
        },
      }));
    },
  };

  return {
    ...scholarshipContext,
    scholarshipActions,
  };
};

export default useScholarship;
