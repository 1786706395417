import React from 'react';
import { Link } from '@cloudhub-ux/core/reach';
import { Block, MenuItem, Text } from '@cloudhub-ux/core';

const TopMenu = (props) => (
  <Block row>
    <Link to="/about" style={{ height: '100%', display: 'flex' }}>
      <MenuItem button>
        <Text milkyWhite>About</Text>
      </MenuItem>
    </Link>
  </Block>
);

export default TopMenu;
