import React from 'react';

import Dialog, {
  DialogActions,
  DialogHeader,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';

import { Block, Input, Button, FieldBlock } from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import AuthContext from './context/AuthContext';

const ChangePasswordDialog = () => {
  const {
    changepasswordopen,
    actions: { changePassword, closeChangePassword },
  } = React.useContext(AuthContext);

  const onChangePassword = (values) => {
    changePassword(values);
  };

  return (
    <Dialog open={changepasswordopen} onClose={closeChangePassword}>
      <DialogHeader>Reset your Password</DialogHeader>
      <Form
        onSubmit={onChangePassword}
        validate={validate}
        render={({ handleSubmit, submitting, pristine }) => (
          <Block>
            <DialogContent>
              <FieldBlock>
                <label>Current Password</label>
                <Field
                  type="password"
                  name="CurrentPassword"
                  component={Input}
                />
              </FieldBlock>
              <FieldBlock>
                <label>New Password</label>
                <Field type="password" name="Password" component={Input} />
              </FieldBlock>
              <FieldBlock>
                <label>Confirm your password</label>
                <Field
                  type="password"
                  name="ConfirmPassword"
                  component={Input}
                />
              </FieldBlock>
            </DialogContent>
            <DialogActions>
              <Button
                contained
                onClick={() => closeChangePassword()}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                medium
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                Save
              </Button>
            </DialogActions>
          </Block>
        )}
      />
    </Dialog>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.CurrentPassword) {
    errors.CurrentPassword = 'Required';
  }
  if (!values.Password) {
    errors.Password = 'Required';
  }
  if (!values.ConfirmPassword) {
    errors.ConfirmPassword = 'Required';
  }

  if (values.Password !== values.ConfirmPassword) {
    errors.ConfirmPassword = 'Passwords do not match';
  }

  return errors;
};

export default ChangePasswordDialog;
