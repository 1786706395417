import React from 'react';

import Dialog, {
  DialogHeader,
  DialogActions,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';
import SignInForm from './SignInForm';
import {} from './context/actions';
import AuthContext from './context/AuthContext';

const SignInDialog = () => {
  const {
    signinopened,
    actions: { closeSignIn },
  } = React.useContext(AuthContext);

  return (
    <Dialog open={signinopened} onClose={closeSignIn} minHeight={500}>
      <DialogHeader>Sign in/Sign up</DialogHeader>
      <DialogContent>
        <SignInForm />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default SignInDialog;
