import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import { navigate } from '@cloudhub-ux/core/reach';
import Container from '@cloudhub-ux/core/Container';
import Pagination from '@cloudhub-ux/core/Pagination';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import Button from '@cloudhub-ux/core/Button';
import Avatar from '@cloudhub-ux/core/Avatar';
import Text from '@cloudhub-ux/core/Text';
import useGraphql from '@cloudhub-ux/graphql';
import { sizes, colors } from 'theme';
import Download from '@mui/icons-material/Download';
import Visibility from '@mui/icons-material/Visibility';

const Journals = () => {
  const [journals, setjournals] = React.useState([]);
  const [scholarshipCount, setscholarshipCount] = React.useState(0);
  const [page, setpage] = React.useState();

  const { Journal } = useGraphql();

  const getJournals = React.useCallback(async () => {
    try {
      if (Journal) {
        const data = await Journal()
          .find({ skip: page * 20, limit: 20 })
          .toJson();
        if (data && Array.isArray(data.items)) {
          setjournals(data.items);
          setscholarshipCount(data.totalCount);
        }
      }
    } catch (error) {}
  }, [Journal, page]);

  React.useEffect(() => {
    getJournals();
  }, [getJournals]);

  return (
    <Block>
      <Block color={colors.primary} padding={sizes.section} flex={false}>
        <Text h1 bold milkyWhite>
          All Journals
        </Text>
      </Block>
      <Container column maxWidth="lg" padding={sizes.padding}>
        <ListMenu>
          {journals.map((item) => (
            <ListMenuItem
              key={(item || {}).id}
              avatar={
                <Avatar src="" alt={(item || {}).Subject}>
                  {((item || {}).Subject || '').substring(0, 1)}
                </Avatar>
              }
              primary={
                <Block space="between">
                  <Text h3 bold>
                    {(item || {}).Subject || ''}
                  </Text>
                  <Block right row flex={false}>
                    <Button
                      onClick={() => navigate(`/app/jornal/${item.id}`)}
                      endIcon={<Visibility />}
                    >
                      View
                    </Button>
                    <Button
                      onClick={() => navigate(`/app/jornal/${item.id}`)}
                      endIcon={<Download />}
                    >
                      Download
                    </Button>
                  </Block>
                </Block>
              }
            />
          ))}
        </ListMenu>
        <Block row center>
          <Pagination
            count={scholarshipCount}
            onChangePage={(p) => setpage(p)}
            rowsPerPage={20}
          />
        </Block>
      </Container>
    </Block>
  );
};

export default Journals;
