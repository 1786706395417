import React, { Fragment } from 'react';
import { Link } from '@cloudhub-ux/core/reach';

import { withStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Drawer,
  Avatar,
} from '@mui/material';

import { Text, Block } from '@cloudhub-ux/core';
import { sizes, colors } from 'theme';

import {
  AccountCircle as UserIcon,
  AssignmentLate as LateIcon,
  Security as PasswordIcon,
  ExitToApp,
} from '@mui/icons-material';

import DefaultUserImage from 'app/auth/assets/images/default-user.png';
import AuthContext from './context/AuthContext';

const styles = () => ({
  accountButton: {
    textTransform: 'capitalize',
  },
  popoverCard: {
    paddingBottom: 45,
  },
  notificationCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: -10,
    marginRight: -10,
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 85,
    height: 85,
  },
  accountInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.darkColor,
  },
  accountDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  editAccountButton: {
    color: colors.darkColor,
    textTransform: 'capitalize',
    borderBottom: '1px solid #CCC',
  },
});

const UserDrawer = ({ open, onCloseUserDrawer }) => {
  const classes = makeStyles(styles);

  const {
    currentUser,
    actions: { openChangePassword, signoutUser },
    isAuthenticated,
    AuthMenu,
  } = React.useContext(AuthContext);

  const { UserType } = currentUser;

  const signOut = () => {
    signoutUser();
  };

  return (
    <Drawer anchor="right" open={open} onClose={onCloseUserDrawer}>
      <div
        tabIndex={0}
        role="button"
        onClick={onCloseUserDrawer}
        onKeyDown={onCloseUserDrawer}
      >
        <Block center middle className={classes.accountInfo}>
          <Avatar
            alt={currentUser.Name || ''}
            src={currentUser.picture || DefaultUserImage}
            className={classNames(classes.avatar, classes.bigAvatar)}
          />
          <Block padding={sizes.padding}>
            <Text
              title
              style={{ color: colors.milkyWhiteColor, textAlign: 'center' }}
            >
              {currentUser.Name}
            </Text>
            <Text
              subTitle
              style={{ color: colors.milkyWhiteColor, textAlign: 'center' }}
            >
              {currentUser.Email}
            </Text>
          </Block>
        </Block>
        <Divider />
        <div>
          <List>
            <Link to="/app/account">
              <ListItem button>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary="My Account" />
              </ListItem>
            </Link>
            <Divider />

            {UserType.includes('Admin') && (
              <Link to="/app/admin">
                <ListItem button>
                  <ListItemIcon>
                    <LateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Administration Panel" />
                </ListItem>
              </Link>
            )}

            <ListItem
              button
              onClick={() => {
                openChangePassword();
              }}
            >
              <ListItemIcon>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" />
            </ListItem>

            {AuthMenu && <AuthMenu />}

            <ListItem
              button
              style={{ backgroundColor: '#DDD' }}
              onClick={signOut}
            >
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer>
  );
};

UserDrawer.defaultProps = {
  onCloseUserDrawer: () => {},
};

export default withStyles(styles)(UserDrawer);
