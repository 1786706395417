import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Block,
  FieldBlock,
  Text,
  Input,
  Button,
  IconButton,
} from '@cloudhub-ux/core';
import {
  DialogActions,
  DialogHeader,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';
import { ArrowBack } from '@mui/icons-material';
import { colors } from 'theme';

const validate = (values) => {
  const errors = {};

  if (!values.Email) {
    errors.resetemail = 'Required';
  }

  return errors;
};

const OTPScreen = (props) => {
  let initialValues = props.initialValues || {};
  const email = initialValues.Email || '';
  const substri = email.substring(2, email.length - 4);
  const em = email.replace(substri, '*******@*******');

  initialValues = { ...initialValues, resetemail: em };
  return (
    <Form
      onSubmit={props.onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Block>
          <DialogHeader onClose={props.onClose}>
            <Text header normal>
              Reset your password
            </Text>
          </DialogHeader>
          <DialogContent column middle>
            <FieldBlock>
              <label>A new password will be sent to your email below</label>
              <Field
                type="email"
                name="Email"
                value={em}
                readOnly
                component={Input}
              />
            </FieldBlock>
          </DialogContent>

          <DialogActions space="between">
            <IconButton disabled={submitting} onClick={props.onBack}>
              <ArrowBack style={{ color: colors.dark, fontSize: 42 }} />
            </IconButton>
            <Button color="dark" disabled={submitting} onClick={handleSubmit}>
              <Text button>Reset Password</Text>
            </Button>
          </DialogActions>
        </Block>
      )}
    />
  );
};

OTPScreen.defaultProps = {
  onBack: () => {},
  onSubmit: () => {},
  onForgotPassword: () => {},
};

export default OTPScreen;
