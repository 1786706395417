import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Container from '@cloudhub-ux/core/Container';
import Pagination from '@cloudhub-ux/core/Pagination';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import Button from '@cloudhub-ux/core/Button';
import Avatar from '@cloudhub-ux/core/Avatar';
import Text from '@cloudhub-ux/core/Text';
import useGraphql from '@cloudhub-ux/graphql';
import { sizes, colors } from 'theme';
import useScholarship from './store/useScholarship';
import dayjs from 'dayjs';
import useAppContext from 'app/context/useAppContext';
import Edit from '@mui/icons-material/Edit';
import { navigate } from '@cloudhub-ux/core/reach';

const MyScholarships = () => {
  const [applications, setapplications] = React.useState([]);
  const [scholarshipCount, setscholarshipCount] = React.useState(0);
  const [page, setpage] = React.useState();
  const { authContext } = useAppContext((state) => ({
    authContext: state.authContext,
  }));

  const { scholarshipActions } = useScholarship();

  const { editApplication } = scholarshipActions;
  const { currentUser } = authContext;

  const { Scholarshipapplication } = useGraphql();

  const getScholarships = React.useCallback(async () => {
    try {
      if (Scholarshipapplication && (currentUser || {}).id) {
        const data = await Scholarshipapplication()
          .find({ 'User.id': currentUser.id, skip: page * 20, limit: 20 })
          .toJson();
        if (data && Array.isArray(data.items)) {
          setapplications(data.items);
          setscholarshipCount(data.totalCount);
        }
      }
    } catch (error) {}
  }, [JSON.stringify(currentUser), page, Scholarshipapplication]);

  React.useEffect(() => {
    getScholarships();
  }, [getScholarships]);

  return (
    <Block>
      <Block color={colors.primary} padding={sizes.section} flex={false}>
        <Text h1 bold milkyWhite>
          My Scholarship Applications
        </Text>
      </Block>
      <Container column maxWidth="lg" padding={sizes.padding}>
        <ListMenu>
          {applications.map((item) => (
            <ListMenuItem
              key={(item || {}).id}
              avatar={
                <Avatar
                  src=""
                  alt={((item || {}).Scholarship || {}).ScholarshipName}
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.milkyWhite,
                    fontSize: 32,
                  }}
                  size={65}
                >
                  {(
                    ((item || {}).Scholarship || {}).ScholarshipName || ''
                  ).substring(0, 1)}
                </Avatar>
              }
              primary={
                <Block row space="between">
                  <Text h3 bold>
                    {((item || {}).Scholarship || {}).ScholarshipName || ''}
                  </Text>
                  {(item || {}).Status === 'NOT_SUBMITTED' && (
                    <Button
                      contained
                      onClick={() => {
                        editApplication({
                          ...(item || {}),
                        });
                        navigate('/app/scholarship/apply');
                      }}
                      color={colors.primary}
                      endIcon={<Edit />}
                    >
                      Edit
                    </Button>
                  )}
                </Block>
              }
              secondary={`${dayjs((item || {}).Timestamp).format(
                'YYYY-MM-DDTHH:mm:ssZ[Z]'
              )}`}
            />
          ))}
        </ListMenu>
        <Block row center>
          <Pagination
            count={scholarshipCount}
            onChangePage={(p) => setpage(p)}
            rowsPerPage={20}
          />
        </Block>
      </Container>
    </Block>
  );
};

export default MyScholarships;
