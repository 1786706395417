import React from 'react';
import { isEmpty } from 'lodash';
import SocketContext from './SockectContext';

export default function useSocketEventListener(eventName, handler) {
  // Create a ref that stores handler
  const savedHandler = React.useRef();
  const { socket } = React.useContext(SocketContext);

  const socketid = socket ? socket.id : null;

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      const isSupported =
        socket &&
        (!socket._callbacks[`$${eventName}`] ||
          isEmpty(socket._callbacks[`$${eventName}`]));

      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      socket.on(`${eventName}`, eventListener);

      // Remove event listener on cleanup
      return () => {
        socket.removeListener(eventName, eventListener);
      };
    },
    [eventName, socketid, savedHandler.current] // Re-run if eventName or element changes
  );
}
