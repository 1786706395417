import React from 'react';
import CONFIG from 'config/CONFIG';
import { UserProps, UserModel, ScholarshipProps } from '@cloudhub-ux/graphql';
import { InitialScholarshipState } from 'app/scholarship/store/useScholarship';
import { InitialSettingsState } from 'app/appsettings/store/useAppSettings';

export interface IInitialState {
  // dispatch: (payload: {
  //   [K in keyof IInitialState]?: IInitialState[K];
  // }) => void;
  getState: () => IInitialState;
  resetState: () => void;
  authContext: {
    token: string;
    isAuthenticated: boolean;
    currentUser: UserProps;
    UserProfile: UserProps;
    authActions: (params: { dispatch: any; User: UserModel }) => {
      [key: string]: () => void;
    };
    AuthMenu: any;
  };
  sharedComponents: {
    [key: string]: React.ReactNode;
  };
  adminContext: {
    adminForms: any;
    adminAxiosInstance: any;
  };
  userContext: {};
  settingsContext: {
    Organization: {
      id: string;
      OrganizationName: string;
      Logo: {
        Url: string;
        Location: string;
      };
    };
    [key: string]: any;
  };
  scholarshipContext: {
    editingScholarship: null | ScholarshipProps;
    scholarshipActions: {
      [key: string]: () => any;
    };
  };
}

const INITIAL_STATE: IInitialState = {
  authContext: {
    token: '',
    isAuthenticated: false,
    currentUser: {
      Name: 'Guest',
      Email: '',
      Phone: '',
      UserType: ['Customer'],
    },
    UserProfile: {
      Name: 'Guest',
      Email: '',
      Phone: '',
      UserType: ['Customer'],
    },
    organization: {
      OrganizationName: CONFIG.OrganizationName,
      Logo: {
        ...CONFIG.Logo,
      },
    },
    loading: false,
  },
  sharedComponents: {},

  settingsContext: InitialSettingsState,

  userContext: {},
  scholarshipContext: InitialScholarshipState,
}; /*as any */

export default INITIAL_STATE;
