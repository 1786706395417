import React from 'react';
import { withStyles } from '@mui/styles';

import { Block, Container, Text } from '@cloudhub-ux/core';
import { colors, sizes } from 'theme';

// import Logo from '../../assets/images/no_available_image.png';
import BG from './assets/images/CloudHubBg.svg';

import './auth.css';
import ResetPasswordForm from './ResetPasswordForm';

const classStyles = () => ({});

const styles = {
  root: {
    position: 'relative',
  },
  introBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -150,
    background: `url(${BG}), linear-gradient(to right, #3b3d61, #4d4a78)`,
    transform: 'skew(0, -3.5deg)',
    minHeight: 600,
  },
};

const ResetPassword = () => (
  <Block style={styles.root}>
    <Block style={styles.introBg} />
    <Block row wrap right>
      <Block />
      <Container maxWidth="sm">
        <Block
          paper
          color={colors.milkyWhite}
          flex={false}
          padding={sizes.padding}
          style={{ minHeight: 400 }}
        >
          <Text header style={{ marginBottom: 20 }}>
            You’ve come to the right place to reset your forgotten password.
          </Text>
          <ResetPasswordForm />
        </Block>
      </Container>
    </Block>
  </Block>
);
export default withStyles(classStyles)(ResetPassword);
