import React from 'react';
import { Block } from '@cloudhub-ux/core';
import { sizes, colors, Images } from 'theme';
import { withStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Menu from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import AccountButton from 'app/auth/AccountButton';
import Drawer from './menu/LeftDrawer';
import Footer from './appsettings/Footer';
import TopMenu from './menu/TopMenu';
import { navigate } from '@cloudhub-ux/core/reach';

let isMobile = false;

if (global.navigator) {
  isMobile = navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  );
}

const App = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  return (
    <Block>
      <Block
        flex={false}
        middle
        style={{
          height: sizes.navBarHeight,
          position: 'relative',
        }}
      >
        <Block
          row
          color={colors.gray4}
          shadow
          style={{
            position: 'fixed',
            right: 0,
            top: 0,
            left: 0,
            zIndex: 9,
          }}
        >
          {!isMobile && (
            <Block flex={false} row middle style={{ width: 300 }}>
              <Block flex={false}>
                {!drawerOpen && (
                  <IconButton
                    onClick={() => setDrawerOpen(true)}
                    style={{ marginRight: sizes.margin }}
                  >
                    <Menu style={{ color: colors.primary }} />
                  </IconButton>
                )}
              </Block>

              <Block
                style={{
                  position: 'relative',
                  height: sizes.navBarHeight,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/app');
                }}
              >
                <Block absolute center middle>
                  <img
                    alt=""
                    src={Images.Logo}
                    style={{
                      width: '100%',
                    }}
                  />
                </Block>
              </Block>

              <Block flex={false}>
                {drawerOpen && (
                  <IconButton
                    onClick={() => setDrawerOpen(false)}
                    style={{ marginLeft: sizes.margin }}
                  >
                    <ChevronLeftIcon style={{ color: colors.primary }} />
                  </IconButton>
                )}
              </Block>
            </Block>
          )}

          <Block row margin={[0, sizes.margin]}>
            {!isMobile && <TopMenu />}

            <Block row middle right>
              <AccountButton />
            </Block>
          </Block>
        </Block>
      </Block>
      <Block row style={{ height: '100%' }}>
        <Block flex={false}>
          <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </Block>
        <Block
          color={colors.milkyWhite}
          style={{
            minHeight: 1000,
          }}
        >
          {children}
        </Block>
      </Block>

      <Block
        flex={false}
        style={{
          position: 'relative',
        }}
      >
        <Footer />
      </Block>
    </Block>
  );
};

const styles = () => ({
  container: {},
});

export default withStyles(styles)(App);
