import React from 'react';
import { Button, Block, Text } from '@cloudhub-ux/core';
import { sizes, colors } from 'theme';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import UserDrawer from './UserDrawer';
import UpdateProfileDialog from './UpdateProfileDialog';
import GetStarted from './start_/GetStarted';
import AuthContext from './context/AuthContext';

import SignInDialog from 'app/auth/SignInDialog';
import ChangePasswordDialog from 'app/auth/ChangePasswordDialog';
import { Link } from '@cloudhub-ux/core/reach';

const AccountButton = ({ userinterface = 'app', style, ...props }) => {
  const { location } = useLocation();
  const [userdraweropen, setUserDrawerOpen] = React.useState(false);

  const { isAuthenticated, currentUser } = React.useContext(AuthContext);

  const renderLinks = () => {
    const name = currentUser.Name || '';
    const names = name.split(' ');

    let haspath = null;

    if (location && typeof location === 'object') {
      haspath =
        location.pathname.includes('/signin') ||
        location.pathname.includes('/signout');
    }

    return (
      <React.Fragment>
        {!isAuthenticated && !haspath && (
          <Button
            outlined
            color={colors.white}
            component={Link}
            to="/app/home/auth"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              borderColor: isAuthenticated ? colors.primary : colors.dark,
              marginRight: sizes.margin,
              borderRadius: 5,
              ...style,
            }}
          >
            <Text
              noWrap
              button
              style={{
                color: isAuthenticated ? colors.primary : colors.dark,
              }}
            >
              Sign in
            </Text>
          </Button>
        )}
        {isAuthenticated && (
          <Button
            padding={sizes.padding}
            onClick={() => setUserDrawerOpen(true)}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              marginRight: sizes.margin,
              borderColor: isAuthenticated ? colors.primary : colors.dark,
            }}
            outlined
          >
            <Text
              body
              style={{
                color: isAuthenticated ? colors.primary : colors.dark,
              }}
            >
              {`Hi, ${names[1] || names[0]}`}
            </Text>
          </Button>
        )}
      </React.Fragment>
    );
  };

  return (
    <Block flex={false} center middle>
      {renderLinks()}
      <UserDrawer
        open={userdraweropen}
        onCloseUserDrawer={() => setUserDrawerOpen(false)}
      />
      <GetStarted />
      <SignInDialog />
      {
        // <ConfirmAccountDialog />
      }
      <ChangePasswordDialog />
      {/* <UpdateProfileDialog
        open={isAuthenticated && (!currentUser.Phone || !currentUser.Email)}
        currentUser={currentUser}
      /> */}
    </Block>
  );
};

AccountButton.defaultProps = {
  textStyle: {
    color: colors.darkColor,
  },
};

export default AccountButton;
