import React from 'react';
import { Block, Card, Text } from '@cloudhub-ux/core';
import { Link } from '@cloudhub-ux/core/reach';
import { colors, Images, sizes } from 'theme';
const Dashboard = () => (
  <Block middle center color={colors.primary}>
    <Text h1 bold white style={{ maxWidth: 500, textAlign: 'center' }}>
      Ministry of Education Portal
    </Text>
    <Block flex={false} row wrap>
      <Link to="/app/scholarship">
        <Card
          style={{
            textDecoration: 'none',
            alignItems: 'center',
            width: 250,
            margin: sizes.doubleBaseMargin,
            height: 300,
            justifyContent: 'space-between',
          }}
        >
          <Text h3 bold dark>
            Scholarships
          </Text>
          <img
            src={Images.scholarship}
            height="150"
            style={{ margin: sizes.doubleBaseMargin }}
          />
          <Text dark h4 style={{ textAlign: 'center' }}>
            The ministry's scholarships and online application system
          </Text>
        </Card>
      </Link>
      <Link to="/app/journal">
        <Card
          style={{
            textDecoration: 'none',
            alignItems: 'center',
            width: 250,
            margin: sizes.doubleBaseMargin,
            height: 300,
            justifyContent: 'space-between',
          }}
        >
          <Text h3 bold dark>
            Journals
          </Text>
          <img
            src={Images.books}
            height="150"
            style={{ margin: sizes.doubleBaseMargin }}
          />
          <Text dark h4 style={{ textAlign: 'center' }}>
            The ministry's Journal Website
          </Text>
        </Card>
      </Link>
    </Block>
  </Block>
);

export default Dashboard;
