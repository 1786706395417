import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import About from './About';
import Terms from './Terms';
import ContactUs from './ContactUs';

const StaticRoutes = () => (
  <Router style={{ flex: 1 }}>
    <About default path="/" />
    <Terms path="/terms" />
    <ContactUs path="/contactus" />
  </Router>
);

export default StaticRoutes;
