const isNode = require('detect-node');

const port = '2051';
const ipAddress = '127.0.0.1';
const afterSignIn = require('./afterSignin').afterSignIn;
module.exports = {
  ipAddress,
  serverPort: port,
  port,
  clientPort: '3003',
  AppCode: 'kamis',
  AppName: 'kamis',
  AppDomain: 'kamis.go.ke',
  API_ENDPOINT: isNode ? 'http://127.0.0.1:1343/api' : '/api',
  FACEBOOKid: '',
  GOOGLEid: '',
  afterSignIn,
};
