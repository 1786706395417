import React from 'react';
import { Block, Text } from '@cloudhub-ux/core';
import { sizes } from 'theme';

import './auth.css';
import ProfileForm from './ProfileForm';

const Profile = ({ currentUser }) => {
  const { email, phone, name } = currentUser;

  return (
    <Block padding={sizes.padding}>
      <Block flex={false}>
        <Text title>Update Profile</Text>
      </Block>
      <ProfileForm initialValues={{ email, phone, name }} />
    </Block>
  );
};

export default Profile;
